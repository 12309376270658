import {
  CREATE_PROPERTY_REQUEST,
  CREATE_PROPERTY_SUCCESS,
  CREATE_PROPERTY_FAILURE,
  GET_ALL_PROPERTIES_REQUEST,
  GET_ALL_PROPERTIES_SUCCESS,
  GET_ALL_PROPERTIES_FAILURE,
  GET_PROPERTY_BY_ID_REQUEST,
  GET_PROPERTY_BY_ID_SUCCESS,
  GET_PROPERTY_BY_ID_FAILURE,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAILURE,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAILURE,
  CLEAR_PROPERTY_DATA,
  UPLOAD_FILES_REQUEST,
  UPLOAD_FILES_SUCCESS,
  UPLOAD_FILES_FAILURE,
} from "../actions/types";

const initialState = {
  loading: false,
  data: null,
  error: null,
  properties: [],
  currentProperty: null,
  uploadSuccess: false,
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROPERTY_REQUEST:
    case GET_ALL_PROPERTIES_REQUEST:
    case GET_PROPERTY_BY_ID_REQUEST:
    case UPDATE_PROPERTY_REQUEST:
    case DELETE_PROPERTY_REQUEST:
    case UPLOAD_FILES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_ALL_PROPERTIES_SUCCESS:
      return {
        ...state,
        loading: false,
        properties: action.payload,
      };
    case GET_PROPERTY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        currentProperty: action.payload,
      };
    case UPDATE_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case DELETE_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_PROPERTY_FAILURE:
    case GET_ALL_PROPERTIES_FAILURE:
    case GET_PROPERTY_BY_ID_FAILURE:
    case UPDATE_PROPERTY_FAILURE:
    case DELETE_PROPERTY_FAILURE:
    case UPLOAD_FILES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_PROPERTY_DATA:
      return {
        ...state,
        data: null, // Restablecer los datos de propiedad a null
        currentProperty: null,
      };
    case UPLOAD_FILES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        uploadSuccess: true,
      };
    default:
      return state;
  }
};

export default propertyReducer;

import { RETRIEVE_ROLES, CREATE_ROLE, DELETE_ROLE } from "../actions/types";

const initialState = [];

const roleReducer = (roles = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ROLES:
      return payload;
    case CREATE_ROLE:
      return [...roles, payload];
    case DELETE_ROLE:
      return roles.filter((role) => role._id !== payload);
    default:
      return roles;
  }
};

export default roleReducer;

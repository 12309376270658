import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import "./index.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import axios from "axios";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

axios.defaults.withCredentials = true;
//Salayaromera
//Dashboard
const Dashboard = React.lazy(() =>
  import("./components/Salayaromera/Dashboard")
);
const Informes = React.lazy(() => import("./components/Salayaromera/Informes"));
const Busquedas = React.lazy(() =>
  import("./components/Salayaromera/Busquedas")
);
const Tasaciones = React.lazy(() =>
  import("./components/Salayaromera/Tasaciones")
);
const TasacionesCrear = React.lazy(() =>
  import("./components/crud/PropiedadesCreate")
);
const TasacionesDetalle = React.lazy(() =>
  import("./components/Salayaromera/TasacionesDetalle")
);
const Reservas = React.lazy(() => import("./components/Salayaromera/Reservas"));
const ReservasCrear = React.lazy(() =>
  import("./components/Salayaromera/ReservasCrear")
);
const ReservasDetalle = React.lazy(() =>
  import("./components/Salayaromera/ReservasDetalle")
);
const ReservasAlquiler = React.lazy(() =>
  import("./components/Salayaromera/ReservasAlquiler")
);
const ReservasAlquilerCrear = React.lazy(() =>
  import("./components/Salayaromera/ReservasAlquilerCrear")
);
const ReservasAlquilerDetalle = React.lazy(() =>
  import("./components/Salayaromera/ReservasAlquilerDetalle")
);
const Clientes = React.lazy(() => import("./components/Salayaromera/Clientes"));
const ClientesCompradores = React.lazy(() =>
  import("./components/Salayaromera/ClientesCompradores")
);
const ClientesVendedores = React.lazy(() =>
  import("./components/Salayaromera/ClientesVendedores")
);
const ClientesCrear = React.lazy(() =>
  import("./components/crud/ClientCreate")
);
const ClientesDetalle = React.lazy(() =>
  import("./components/crud/ClientEdit")
);
const Usuarios = React.lazy(() => import("./components/Salayaromera/Usuarios"));
const Roles = React.lazy(() => import("./components/Salayaromera/Roles"));
const Permisos = React.lazy(() => import("./components/Salayaromera/Permisos"));
const ISO = React.lazy(() => import("./components/Salayaromera/ISO"));

// const Switcherlayout = React.lazy(() => import('./components/switcherlayout'))
//App
const App = React.lazy(() => import("./components/app"));
const Custompages = React.lazy(() => import("./components/custompages"));

//Clientes
//Estadisticas
const Estadisticas = React.lazy(() =>
  import("./components/Estadisticas/Estadisticas")
);

const UnderConstruction = React.lazy(() =>
  import("./components/pages/UnderConstruction/UnderConstruction")
);

//custom Pages
const Login = React.lazy(() => import("./components/CustomPages/Login/Login"));
const Admin = React.lazy(() => import("./components/CustomPages/Login/Admin"));

const SignUp = React.lazy(() =>
  import("./components/CustomPages/Register/Register")
);
const ForgotPassword = React.lazy(() =>
  import("./components/CustomPages/ForgotPassword/ForgotPassword")
);
const LockScreen = React.lazy(() =>
  import("./components/CustomPages/LockScreen/LockScreen")
);
//Errorpages
const Errorpage400 = React.lazy(() =>
  import("./components/ErrorPages/ErrorPages/400/400")
);
const Errorpage401 = React.lazy(() =>
  import("./components/ErrorPages/ErrorPages/401/401")
);
const Errorpage403 = React.lazy(() =>
  import("./components/ErrorPages/ErrorPages/403/403")
);
const Errorpage500 = React.lazy(() =>
  import("./components/ErrorPages/ErrorPages/500/500")
);
const Errorpage503 = React.lazy(() =>
  import("./components/ErrorPages/ErrorPages/503/503")
);

const Darkmode = () => {
  document.querySelector(".app").classList.toggle("dark-mode");
};

const Loaderimg = () => {
  return (
    <div id="global-loader">
      <img
        src={require("./assets/images/loader.svg").default}
        className="loader-img"
        alt="Loader"
      />
    </div>
  );
};
const Root = () => {
  useEffect(() => {
    //Darkmode()
  }, []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <React.Suspense fallback={Loaderimg()}>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              element={<Login />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/admin`}
              element={<Admin />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/signup`}
              element={<SignUp />}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              element={<PrivateRoute />}
            >
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                element={<App />}
              >
                <Route>
                  <Route
                    path={`${process.env.PUBLIC_URL}/dashboard`}
                    element={<Dashboard />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/informes`}
                    element={<Informes />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/busquedas`}
                    element={<Busquedas />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/tasaciones`}
                    element={<Tasaciones />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/tasacionesdetalle/:id`}
                    element={<TasacionesDetalle />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/reservas`}
                    element={<Reservas />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/reservascrear`}
                    element={<ReservasCrear />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/reservasdetalle/:id`}
                    element={<ReservasDetalle />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/reservas-alquiler`}
                    element={<ReservasAlquiler />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/reservas-alquiler-crear`}
                    element={<ReservasAlquilerCrear />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/reservas-alquiler-detalle/:id`}
                    element={<ReservasAlquilerDetalle />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/clientesdetalle`}
                    element={<ClientesDetalle />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/clientescrear`}
                    element={<ClientesCrear />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/tasacionescrear`}
                    element={<TasacionesCrear />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/usuarios`}
                    element={<Usuarios />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/roles`}
                    element={<Roles />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/permisos`}
                    element={<Permisos />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/iso`}
                    element={<ISO />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/clientes`}
                    element={<Clientes />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/clientescompradores`}
                    element={<ClientesCompradores />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/clientesvendedores`}
                    element={<ClientesVendedores />}
                  />
                  <Route
                    path={`${process.env.PUBLIC_URL}/estadisticas`}
                    element={<Estadisticas />}
                  />
                </Route>
              </Route>
            </Route>
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<Custompages />}
            >
              <Route
                path={`${process.env.PUBLIC_URL}/pages/underConstruction`}
                element={<UnderConstruction />}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/forgotPassword`}
                element={<ForgotPassword />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/custompages/lockScreen`}
                element={<LockScreen />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/custompages/errorpages/errorpage401`}
                element={<Errorpage401 />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/custompages/errorpages/errorpage403`}
                element={<Errorpage403 />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/custompages/errorpages/errorpage500`}
                element={<Errorpage500 />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/custompages/errorpages/errorpage503`}
                element={<Errorpage503 />}
              />
            </Route>
            <Route path="*" element={<Errorpage400 />} />
          </Routes>
        </React.Suspense>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
    </Provider>
  );
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkAuth } from "../redux/actions/actions";

const PrivateRoute = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    localStorage.setItem("lastPath", location.pathname);
    return <Navigate to={`${process.env.PUBLIC_URL}/login`} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;

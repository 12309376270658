import {
  RETRIEVE_USERS,
  CREATE_USER,
  DELETE_USER,
  UPDATE_USER,
} from "../actions/types";

const initialState = [];

const userReducer = (users = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_USERS:
      return payload;
    case CREATE_USER:
      return [...users, payload];
    case UPDATE_USER:
      return users.map((user) =>
        user._id === payload._id ? { ...user, ...payload } : user
      );
    case DELETE_USER:
      return users.filter((user) => user._id !== payload);
    default:
      return users;
  }
};

export default userReducer;

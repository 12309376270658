import axios from "axios";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const LOGIN_ERROR = "LOGIN_ERROR";

//const API = "http://localhost:3000/api";
const API = "https://salaya-back.acrons.net/api";
axios.defaults.withCredentials = true;

export const logout = () => {
  return async (dispatch) => {
    try {
      await axios.post(`${API}/auth/logout`, {});
      dispatch({ type: LOGOUT });
    } catch (error) {
      console.log(error);
    }
  };
};

export const login = ({ email, password, token }) => {
  return async (dispatch) => {
    if (token) {
      dispatch({ type: LOGIN, payload: { token } });
      return;
    }

    try {
      const res = await axios.post(`${API}/auth/login`, { email, password });
      const data = res.data;

      if (data) {
        dispatch({
          type: LOGIN,
          payload: {
            id: data.id, // Asignar el ID de usuario
            rol: data.rol, // Asignar el objeto rol completo
          },
        });
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: { detail: "Datos de autenticación incompletos" },
        });
      }
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: error.response?.data || { detail: "Error desconocido" },
      });
      console.log("Error durante el login:", error);
    }
  };
};

export const checkAuth = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${API}/auth/check-user`);
      const data = res.data;

      if (data.status === "User logged" && data.id && data.rol) {
        dispatch({
          type: LOGIN,
          payload: {
            id: data.id,
            rol: data.rol,
          },
        });
      } else {
        dispatch({ type: LOGOUT });
      }
    } catch (error) {
      console.error("Error verifying authentication", error);
      dispatch({ type: LOGOUT });
    }
  };
};

export const adminLogin = ({ email, password }) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `${API}/admin`,
        data: { email: email, password: password },
      });
      dispatch({ type: LOGIN, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
};

export const signIn = ({ name, email, password }) => {
  return async (dispatch) => {
    try {
      const res = await axios({
        method: "POST",
        url: `${API}/user`,
        data: { name: name, email: email, password: password },
      });
      dispatch({ type: LOGIN, payload: res.data });
    } catch (error) {
      console.log(error);
    }
  };
};

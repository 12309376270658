import {
  FETCH_RESERVATIONS_REQUEST,
  FETCH_RESERVATIONS_SUCCESS,
  FETCH_RESERVATIONS_FAILURE,
  DELETE_RESERVATION_SUCCESS,
  DELETE_RESERVATION_FAILURE,
  DELETE_RESERVATION_REQUEST,
} from "../actions/types";

const initialState = {
  loading: false,
  reservations: [],
  error: "",
};

const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RESERVATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RESERVATIONS_SUCCESS:
      return {
        loading: false,
        reservations: action.payload,
        error: "",
      };
    case FETCH_RESERVATIONS_FAILURE:
      return {
        loading: false,
        reservations: [],
        error: action.payload,
      };

    case DELETE_RESERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        reservations: state.reservations.filter(
          (reservation) => reservation._id !== action.payload
        ),
      };

    case DELETE_RESERVATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reservationReducer;

import {
  RETRIEVE_CLIENTS,
  RETRIEVE_CLIENT_BY_ID,
  CREATE_CLIENT,
  CREATE_CLIENT_ERROR,
  DELETE_CLIENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_ERROR,
  CLEAR_CLIENT_ERROR,
} from "../actions/types";

const initialState = {
  clients: [],
  error: null,
  currentClient: null,
};

const clientReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_CLIENTS:
      return {
        ...state,
        clients: payload,
        error: null,
      };
    case RETRIEVE_CLIENT_BY_ID:
      return {
        ...state,
        currentClient: payload,
        error: null,
      };
    case CREATE_CLIENT:
      return {
        ...state,
        clients: [...state.clients, payload],
        Error: null,
      };
    case CREATE_CLIENT_ERROR:
      return {
        ...state,
        error: payload,
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((client) => client._id !== payload), // Filtrar el cliente eliminado
        error: null,
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        clients: state.clients.map((client) =>
          client._id === payload._id ? payload : client
        ),
        error: null,
      };
    case UPDATE_CLIENT_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_CLIENT_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default clientReducer;

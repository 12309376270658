import { LOGIN, LOGOUT, LOGIN_ERROR } from "../actions/actions";

const initialState = {
  isAuthenticated: null,
  userId: null,
  role: null,
  error: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        userId: null,
        role: null,
      };
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        userId: action.payload?.id || null,
        role: action.payload?.rol?.name || null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.payload?.detail || "Error al iniciar sesión",
      };
    default:
      return state;
  }
}

// Roles
export const RETRIEVE_ROLES = "RETRIEVE_ROLES";
export const RETRIEVE_ROLE = "RETRIEVE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const CREATE_ROLE = "CREATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";

// Users
export const RETRIEVE_USERS = "RETRIEVE_USERS";
export const RETRIEVE_USER = "RETRIEVE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";

// Clients
export const RETRIEVE_CLIENTS = "RETRIEVE_CLIENTS";
export const RETRIEVE_CLIENT_BY_ID = "RETRIEVE_CLIENT_BY_ID";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const CREATE_CLIENT_ERROR = "CREATE_CLIENT_ERROR";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const UPDATE_CLIENT_ERROR = "UPDATE_CLIENT_ERROR";
export const CLEAR_CLIENT_ERROR = "CLEAR_CLIENT_ERROR";

//properties
export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS";
export const CREATE_PROPERTY_FAILURE = "CREATE_PROPERTY_FAILURE";

export const GET_ALL_PROPERTIES_REQUEST = "GET_ALL_PROPERTIES_REQUEST";
export const GET_ALL_PROPERTIES_SUCCESS = "GET_ALL_PROPERTIES_SUCCESS";
export const GET_ALL_PROPERTIES_FAILURE = "GET_ALL_PROPERTIES_FAILURE";

export const GET_PROPERTY_BY_ID_REQUEST = "GET_PROPERTY_BY_ID_REQUEST";
export const GET_PROPERTY_BY_ID_SUCCESS = "GET_PROPERTY_BY_ID_SUCCESS";
export const GET_PROPERTY_BY_ID_FAILURE = "GET_PROPERTY_BY_ID_FAILURE";

export const UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST";
export const UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS";
export const UPDATE_PROPERTY_FAILURE = "UPDATE_PROPERTY_FAILURE";

export const DELETE_PROPERTY_REQUEST = "DELETE_PROPERTY_REQUEST";
export const DELETE_PROPERTY_SUCCESS = "DELETE_PROPERTY_SUCCESS";
export const DELETE_PROPERTY_FAILURE = "DELETE_PROPERTY_FAILURE";
export const CLEAR_PROPERTY_DATA = "CLEAR_PROPERTY_DATA";

export const UPLOAD_FILES_REQUEST = "UPLOAD_FILES_REQUEST";
export const UPLOAD_FILES_SUCCESS = "UPLOAD_FILES_SUCCESS";
export const UPLOAD_FILES_FAILURE = "UPLOAD_FILES_FAILURE";

//reservation
export const FETCH_RESERVATIONS_REQUEST = "FETCH_RESERVATIONS_REQUEST";
export const FETCH_RESERVATIONS_SUCCESS = "FETCH_RESERVATIONS_SUCCESS";
export const FETCH_RESERVATIONS_FAILURE = "FETCH_RESERVATIONS_FAILURE";
export const DELETE_RESERVATION_REQUEST = "DELETE_RESERVATION_REQUEST";
export const DELETE_RESERVATION_SUCCESS = "DELETE_RESERVATION_SUCCESS";
export const DELETE_RESERVATION_FAILURE = "DELETE_RESERVATION_FAILURE";

import auth from "../reducers/auth";
import users from "../reducers/users";
import roles from "../reducers/roles";
import clients from "../reducers/clients";
import property from "../reducers/property";
import reservation from "../reducers/reservation";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth,
  users,
  roles,
  clients,
  property,
  reservation,
});

export default rootReducer;
